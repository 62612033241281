<!-- Todo: 
  - Break file into components 
  - Evaluate why some numbers are skipped
-->
<template>
  <div class="container">
    <div class="py-4 text-center my-5 col-lg-8 mx-auto">
      <h1 class="app-h1 pb-0">Clean up and Validate your Spreadsheets</h1>
      <p class="text-secondary">
        Get a version of your Spreadsheets with valid data
      </p>
    </div>
    <!-- File upload -->
    <div class="app-section" v-if="this.file == null">
      <input type="file" class="file-upload" @change="handleUpload($event)" />
      <div class="w-100 text-center d-flex flex-column align-items-center">
        <div
          class="d-block d-lg-flex align-items-center upload__label text-center"
        >
          <i class="bi-cloud-plus icon"></i>
          <span class="text">Drag and drop file here or click to upload</span>
        </div>
      </div>
    </div>
    <div class="file-container" v-if="this.file != null">
      <div
        class="file-container__file-info d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <i class="bi-file-spreadsheet mr-2 text-secondary"></i>
          <span class="small text-secondary">{{ fileName }}</span>
          <div class="loader" v-if="uploading"></div>
        </div>
        <span class="h4 text-danger mb-0 cursor-pointer" @click="reset">
          <i class="bi-x-lg"></i>
        </span>
      </div>
      <div class="d-flex align-items-center mt-3 w=-">
        <button
          class="action-button action-button--green"
          @click="uploadFile"
          v-if="!uploaded"
          :disabled="uploading"
        >
          Upload
        </button>
      </div>
    </div>
    <div class="mt-5" v-if="uploaded">
      <p v-if="!cleaned">
        Your Document has the following columns which we can be clean up. Select
        the columns you want cleaned up.
      </p>
      <div class="text-center" v-if="cleaned">
        <div class="my-2 text-center icon--text">
          <i class="bi-check-circle text-success"></i>
        </div>
        <h3 class="h3 text-dark">Done</h3>
        <p class="small text-secondary">Your file has been cleaned up.</p>
      </div>
      <div class="columns d-flex align-items-center" v-if="!cleaned">
        <div
          v-for="key in onlyShowSupportedKeys"
          :key="key"
          class="column keys"
          :class="{ selected: keysToCleanUp.includes(key) }"
          @click="toggleKeySelection(key)"
        >
          {{ key }}
        </div>
      </div>

      <button
        @click="validateKeys"
        :disabled="keysToCleanUp == 0"
        v-if="!cleaned"
        class="action-button action-button--dark"
      >
        Clean Up
      </button>
    </div>
    <div class="my-5">
      <h2 class="app-h2">How it works</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="app-info-card">
            <div class="icon-wrapper bg--laurel-green text-white">
              <i class="bi-cloud-arrow-up"></i>
            </div>
            <h3>Upload a file</h3>
            <p>Pick a CSV, XLS or XLSX containing your data</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="app-info-card">
            <div class="icon-wrapper bg--royal-purple text-white">
              <i class="bi-check2-square"></i>
            </div>
            <h3>Select columns</h3>
            <p>
              Pick the columns from your document you want cleaned up. For now
              we only clean up and validate phone numbers, full names and emails
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="app-info-card">
            <div class="icon-wrapper bg--black-coral text-white">
              <i class="bi-cloud-download"></i>
            </div>
            <h3>Download</h3>
            <p>Download your clean file together with an error file</p>
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal" v-if="download_ready">
      <div class="text-center app-modal__content">
        <div class="w-100 pt-2 pb-5">
          <div class="d-flex justify-content-end h3 mb-5 cursor-pointer">
            <i class="bi-x-lg" @click="reset"></i>
          </div>
          <div class="mb-5">
            <h3 class="h3 text-dark">Files ready for download</h3>
            <div class="mt-4 d-flex flex-column">
              <span class="text-secondary mt-3 small"
                >Your document has {{ this.raw_data.length }} rows</span
              >
              <span class="text-secondary mt-3 small"
                >{{ this.clean_data.length }} rows were cleaned
                successfully</span
              >
              <span class="text-secondary mt-3 small"
                >{{ this.error_data.length }} rows were cleaned with
                errors</span
              >
            </div>
          </div>
          <div class="mt-4">
            <download-excel
              :data="clean_data"
              :name="`${fileName}_clean_${Date.now()}.xlsx`"
              class="action-button action-button--dark"
            >
              Download Clean Data
            </download-excel>
            <download-excel
              :data="error_data"
              class="action-button action-button--danger"
              :name="`${fileName}_withErrors_${Date.now()}.xlsx`"
            >
              Download Error Data
            </download-excel>
          </div>
        </div>
      </div>
    </div>
    <footer class="my-5 d-flex align-items-center justify-content-between">
      <span class="small text-muted"
        >Made with <i class="bi-balloon-heart-fill text-danger"></i> by the
        Attenvo Team</span
      >
      <span class="text-small text-dark">v1</span>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import parsePhoneNumber from "libphonenumber-js";
import { getCountryNameByISO } from "@/helpers";
import {
  verifyPhoneNumber,
  sanitizePhoneNumber,
  COUNTRY_CODE,
} from "nigerian-phone-number-validator";
export default {
  name: "HomeView",
  data() {
    return {
      msg: "Hello World",
      file: null,
      uploading: false,
      file_ready: false,
      download_ready: false,
      uploaded: false,
      cleaned: false,
      fileName: "file",
      keys: [],
      raw_data: [],
      clean_data: [],
      error_data: [],
      keysToCleanUp: [],
      formattedKeys: [],
      error_types: ["bad_format"],
    };
  },
  computed: {
    onlyShowSupportedKeys() {
      // Only show keys that include email, phone or full name
      return this.keys.filter((key) => {
        return (
          key.toLowerCase().includes("email") ||
          key.toLowerCase().includes("phone") ||
          key.toLowerCase().includes("full name")
        );
      });
    },
  },
  methods: {
    reset() {
      this.file = null;
      this.uploading = false;
      this.file_ready = false;
      this.cleaned = false;
      this.uploaded = false;
      this.fileName = "file";
      this.keys = [];
      this.raw_data = [];
      this.clean_data = [];
      this.error_data = [];
      this.keysToCleanUp = [];
      this.formattedKeys = [];
      this.error_types = ["bad_format"];
      this.download_ready = false;
    },
    async handleUpload(e) {
      // Check if file is empty
      if (e.target.files.length === 0) {
        this.$toast.error("Please select a file");
        return;
      }
      // Check if file is a csv or excel file
      if (
        e.target.files[0].type !== "application/vnd.ms-excel" &&
        e.target.files[0].type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        e.target.files[0].type !== "text/csv"
      ) {
        this.$toast.error("Only CSV and Excel files are supported");
        return;
      }
      // Check if file is above 2mb
      if (e.target.files[0].size > 2000000) {
        this.$toast.error("File size must not be more than 2mb");
        return;
      }
      this.file = e.target.files[0];
      this.fileName = this.file.name.split(".").shift();
      console.log(this.fileName);
      // remove file extension
    },
    async uploadFile() {
      try {
        this.uploading = true;
        const formData = new FormData();
        formData.append("file", this.file);
        const res = await axios.post(
          "https://dev.agent.attenvo.com/api/v1/import/json",
          formData
        );
        const data = res.data.message[0];
        data.forEach((item) => {
          this.raw_data.push(item);
        });
        this.formatKeys();
        // create new array with response
        this.keys = Object.keys(this.raw_data[0]);
        this.uploaded = true;
        // this.json_data = res.data.data;
      } catch (error) {
        this.$toast.error(
          "An error occured while uploading file. Please try again"
        );
        console.log(error);
      } finally {
        this.uploading = false;
      }
    },
    toggleKeySelection(key) {
      if (this.keysToCleanUp.includes(key)) {
        this.keysToCleanUp = this.keysToCleanUp.filter((item) => item !== key);
      } else {
        this.keysToCleanUp.push(key);
      }
      console.log(this.keysToCleanUp);
    },
    validateKeys() {
      this.keysToCleanUp.forEach((key) => {
        // turn key to lower case and remove spaces
        const trimmed_key = key.toLowerCase().replace(/\s/g, "");
        // Check if key is a name field
        if (trimmed_key.includes("name")) {
          console.log("Name field found");
          if (trimmed_key.includes("first") || trimmed_key.includes("last")) {
            console.log("First or last name found");
          } else {
            console.log("Name field found but not first or last name");
            this.cleanUpNameFileds(key);
          }
          return;
        }
        // Check if key is a phone number field
        if (trimmed_key.includes("phone")) {
          console.log("Phone number field found");
          this.cleanUpPhoneFields(key);
          return;
        }
        // Key is an email field
        if (trimmed_key.includes("email")) {
          console.log("Email field found");
          this.cleanUpEmailFields(key);
          return;
        }
      });
      this.keyRelatedItemsCloserToEachOther();
      this.cleaned = true;
      setTimeout(() => {
        this.seperateData();
      }, 3000);
    },
    cleanUpNameFileds(fieldName) {
      // Break up name fields into first, last name and other name
      this.raw_data.forEach((item) => {
        const name = item[fieldName];
        // Ensure name is not empty
        if (name === null) {
          item[fieldName] = " ";
          item["Name Comment"] = "Name is empty";

          return;
        }
        const nameArray = name.split(" ");
        if (nameArray.length === 2) {
          item["First Name"] = nameArray[0];
          item["Last Name"] = nameArray[1];
        } else if (nameArray.length === 3) {
          item["First Name"] = nameArray[0];
          item["Last Name"] = nameArray[2];
          item["Other Name"] = nameArray[1];
        } else {
          item["First Name"] = nameArray[0];
          item["Last Name"] = nameArray[nameArray.length - 1];
          item["Other Name"] = nameArray
            .slice(1, nameArray.length - 1)
            .join(" ");
        }
      });
    },
    cleanUpEmailFields(fieldName) {
      for (let i = 0; i < this.raw_data.length; i++) {
        const item = this.raw_data[i];
        const email = item[fieldName];
        if (email === null) {
          item[fieldName] = " ";
          return;
        }
        //  check if email is has only one @
        if (email.split("@").length !== 2) {
          item["Email Comment"] = "Email has more than one @ symbol";

          item.error_type = "bad_format";
          return;
        }
        // check if email has a domain name
        if (!email.includes(".")) {
          item["Email Comment"] = "Email has no domain name";

          item.error_type = "bad_format";
          return;
        }
        // Check if email has period at beginning or end
        if (email.startsWith(".") || email.endsWith(".")) {
          item["Email Comment"] = "Email has period at beginning or end";

          item.error_type = "bad_format";
          return;
        }
        // Check if email starts or ends with @
        if (email.startsWith("@") || email.endsWith("@")) {
          item["Email Comment"] = "Email has @ at beginning or end";

          item.error_type = "bad_format";
          return;
        }
      }
    },
    cleanUpPhoneFields(fieldName) {
      for (let i = 0; i < this.raw_data.length; i++) {
        console.log(`row count ${i}`);
        const item = this.raw_data[i];
        var phone = item[fieldName];
        if (phone === null) {
          item[fieldName] = " ";
          continue;
        }
        phone = `"${phone}"`;
        // remove quotes from phone number
        phone = phone.replace(/"/g, "");
        phone = phone.toString();
        // Remove all spaces
        phone = phone.replace(/\s/g, "");
        // remove all brackets
        phone = phone.replace(/\(|\)/g, "");
        // remove all dashes
        phone = phone.replace(/-/g, "");
        // remove all plus signs
        phone = phone.replace(/\+/g, "");
        const nigerian = verifyPhoneNumber(phone);
        if (nigerian) {
          console.log("Nigerian phone number found");
          // remove 234 from phone number
          phone = phone.replace(/^234+/, "");
          item[fieldName] = phone;
          item["Phone Country"] = "Nigeria";
          item["Phone Country Code"] = `+234`;
          continue;
        }
        if (phone.substring(0, 2) == "80") {
          phone = `234${phone}`;
        }
        if (phone.substring(0, 2) == "90") {
          phone = `234${phone}`;
        }
        if (phone.substring(0, 2) == "70") {
          phone = `234${phone}`;
        }
        if (phone.startsWith("080") && phone.length == 11) {
          phone = `234${phone}`;
        }
        if (phone.startsWith("090") && phone.length == 11) {
          phone = `234${phone}`;
        }
        if (phone.startsWith("070") && phone.length == 11) {
          phone = `234${phone}`;
        }
        if (phone.substring(0, 2) == "81" && phone.length >= 10) {
          phone = `234${phone}`;
        }

        const phoneNumber = parsePhoneNumber(`+${phone}`);
        if (!phoneNumber) {
          item["Phone Comment"] = "Missing country code";
          item.error_type = "bad_format";
        } else {
          if (phoneNumber.isValid()) {
            item[fieldName] = phoneNumber.nationalNumber;
            item["Phone Country"] = getCountryNameByISO(phoneNumber.country);
            item["Phone Country Code"] = `+${phoneNumber.countryCallingCode}`;
          } else {
            console.log(phone, "is badly formatted");
            item["Phone Comment"] = "Phone number is badly formmatted";
            item.error_type = "bad_format";
          }
        }
      }
    },
    keyRelatedItemsCloserToEachOther() {
      // Check if keys are close to each other and if they are, move them closer to each other
      this.raw_data.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          const keyIndex = keys.indexOf(key);
          if (keyIndex > 0) {
            const previousKey = keys[keyIndex - 1];
            const previousKeyIndex = keys.indexOf(previousKey);
            if (keyIndex - previousKeyIndex > 1) {
              // Move key closer to previous key
              const temp = item[key];
              item[key] = item[previousKey];
              item[previousKey] = temp;
            }
          }
        });
      });
    },
    //remove underscore, split keys in raw data and capitalize first letter of each word
    formatKeys() {
      this.raw_data.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          const formattedKey = key
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          item[formattedKey] = item[key];
          delete item[key];
        });
      });
    },
    async seperateData() {
      this.clean_data = [];
      this.error_data = [];
      // Seperate data into two arrays, one for valid data and one for invalid data
      for (let i = 0; i < this.raw_data.length; i++) {
        const item = this.raw_data[i];
        const keys = Object.keys(item);
        const emailKey = keys.find((key) =>
          key.toLowerCase().includes("email")
        );
        const phoneKey = keys.find((key) =>
          key.toLowerCase().includes("phone")
        );
        if (item[emailKey] === " " && item[phoneKey] === " ") {
          item["Email Comment"] = "Email is missing";
          item["Phone Comment"] = "Phone number is missing";

          this.error_data.push(item);
          continue;
        }
        if (item.error_type == "bad_format") {
          delete item.error_type;
          this.error_data.push(item);
        } else {
          delete item.error_type;
          this.clean_data.push(item);
        }
      }
      console.log("Clean Data=>", this.clean_data);
      console.log("Error Data =>", this.error_data);
      // $("#exampleModal").modal("show");
      this.download_ready = true;
    },
  },
};
</script>

<style></style>
